import { detectOverflow } from "@popperjs/core";
import { drop } from "lodash";
import styles from "./coffee.main.scss";

/* create title page */
function createSectionTitle(title_text, container) {
  let title_container = document.createElement("div");
  title_container.classList.add(styles.container, styles.clearfix);

  let dot = document.createElement("span");
  dot.classList.add(styles.dot);

  title_container.appendChild(dot);

  let title = document.createElement("div");
  title.classList.add(styles.title);
  title.innerHTML = title_text;

  title_container.appendChild(title);

  container.appendChild(title_container);
}

function createTextInput(
  label_text,
  place_holder,
  input_name,
  input_type,
  input_id,
  enabled = true,
  default_value = "",
  visible = true,
  styles = []
) {
  let container = document.createElement("div");
  container.id = "js__container_" + input_id;
  container.classList.add("form-floating", "row", ...styles);

  let col = document.createElement("div");
  col.classList.add("col-lg-12", "col-md-6", "col-sm-6");

  let input = document.createElement("input");
  input.type = input_type;
  if (input_type == "file") {
    input.classList.add("form-control", "g-2");
  } else {
    input.classList.add("form-control", "g-1");
  }
  input.id = input_id;
  input.placeholder = place_holder;
  input.name = input_name;
  input.disabled = !enabled;
  input.value = default_value;
  input.setAttribute("aria-describedby", "error_message_" + input_id);

  let error_message = document.createElement("span");
  error_message.classList.add(styles.error_message);
  error_message.style.color = "red";
  error_message.style.marginTop = "1rem";
  error_message.style.marginRight = "0";
  error_message.id = "error_message_" + input_id;

  let label = document.createElement("label");
  label.classList.add("g-1");
  label.setAttribute("for", input_name);
  label.innerText = label_text;

  container.appendChild(input);
  container.appendChild(label);
  container.appendChild(error_message);

  if (visible == false) {
    container.style.display = "none";
  }

  return col.appendChild(container);
}

function createOptionList(
  label_text,
  input_name,
  input_id,
  option_list = [{ text: "Selecciona uno", value: -1 }],
  visible = true
) {
  let container = document.createElement("div");
  container.id = "js__container_" + input_id;

  container.classList.add("form-floating", "row");

  let col = document.createElement("div");
  col.classList.add("col-lg-12", "col-md-6", "col-sm-6");

  let input = document.createElement("select");
  input.classList.add("form-control", "g-1");
  input.id = input_id;
  input.name = input_name;
  input.setAttribute("aria-describedby", "error_message_" + input_id);

  let error_message = document.createElement("span");
  error_message.style.color = "red";
  error_message.style.marginTop = "1rem";
  error_message.style.marginRight = "0";
  error_message.id = "error_message_" + input_id;

  option_list.forEach((element) => {
    let option = document.createElement("option");
    option.value = element.value;
    option.innerText = element.text;

    input.appendChild(option);
  });

  let label = document.createElement("label");
  label.classList.add("g-1");
  label.setAttribute("for", input_name);
  label.innerText = label_text;

  container.appendChild(input);
  container.appendChild(label);
  container.appendChild(error_message);

  if (visible == false) {
    container.style.display = "none";
  }

  return col.appendChild(container);
}

const methods = {
  createSectionTitle: (title, container) => {
    createSectionTitle(title, container);
  },
  createTextInput: (
    label_text,
    place_holder,
    input_name,
    input_type,
    input_id,
    enabled,
    default_value,
    visible,
    styles
  ) =>
    createTextInput(
      label_text,
      place_holder,
      input_name,
      input_type,
      input_id,
      enabled,
      default_value,
      visible,
      styles
    ),
  createOptionList: (label_text, input_name, input_id, option_list, visible) =>
    createOptionList(label_text, input_name, input_id, option_list, visible),
  base_url: "https://lnbpback.truewisdom.co",
  id_season: 32
};

export default methods;
