import styles from "./footer.scss";
import logo from "../../assets/logos/logo_footer.png";
import facebook_icon from "../../assets/icons/14_Facebook_fem.svg";
import facebook_icon_select from "../../assets/icons/14_Facebook_Seleccion.svg";
import istagram_icon from "../../assets/icons/2_Instagram_fem.svg";
import istagram_icon_select from "../../assets/icons/14_Instagram_Seleccion.svg";
import twitter_icon from "../../assets/icons/15_Twitter_fem.svg";
import twitter_icon_select from "../../assets/icons/15_Twitter_Seleccion.svg";
import youtube_icon from "../../assets/icons/16_Youtube_fem.svg";
import youtube_icon_select from "../../assets/icons/16_Youtube_Seleccion.svg";
import twt_icon from "../../assets/logos/twt_logo.svg";

import logo_app from "../../assets/logos/logo_app.png";
import logo_sponsor_1 from "../../assets/logos/LogosLNBP_1.png";
import logo_sponsor_2 from "../../assets/logos/LogosLNBP_2.png";
import logo_sponsor_3 from "../../assets/logos/LogosLNBP_3.png";
import logo_sponsor_4 from "../../assets/logos/LogosLNBP_4.png";
import logo_android from "../../assets/logos/apple.png";
import logo_apple from "../../assets/logos/google.png";
import check_icon from "../../assets/icons/1_check2.png";

import coffee_main from "../coffee.main";
const base_url = coffee_main.base_url;

function render(base_url, id_season) {
  //logo
  //content.appendChild(displayNews(base_url));
  displayLogo();

  let footer_container = document.getElementById("js__footer_content");
  footer_container.classList.add(styles.footer_container);
  //middle content
  displayContent(base_url, id_season);

  //end content
  displaySocialNetwork();
}

function displayLogo() {
  let logo_img = document.createElement("img");
  logo_img.src = logo;
  logo_img.classList.add(styles.footer_logo);

  let logo_container = document.getElementById("js__footer_logo");
  logo_container.appendChild(logo_img);
}

function displaySocialNetwork() {
  let social_network_container = document.getElementById(
    "js__footer_social_network"
  );

  //social networks
  let social_networks = document.createElement("div");
  social_networks.classList.add(
    "col-sm-12",
    "col-lg-3",
    "col-md-3",
    styles.left,
    styles.social_networks
  );
  social_network_container.appendChild(social_networks);

  let span_social_network = document.createElement("span");
  span_social_network.innerHTML = "LNBP OFICIAL";
  social_networks.appendChild(span_social_network);

  let facebook_anchor = document.createElement("a");
  let instagram_anchor = document.createElement("a");
  let twitter_anchor = document.createElement("a");
  let youtube_anchor = document.createElement("a");

  let facebook_img = document.createElement("img");
  facebook_img.src = facebook_icon;
  facebook_anchor.href = "https://www.facebook.com/LNBPoficial/";
  facebook_anchor.appendChild(facebook_img);

  let instagram_img = document.createElement("img");
  instagram_img.src = istagram_icon;
  instagram_anchor.href = "https://www.instagram.com/lnbpoficial/";
  instagram_anchor.appendChild(instagram_img);
  let twitter_img = document.createElement("img");
  twitter_img.src = twitter_icon;
  twitter_anchor.href = "https://twitter.com/LNBPoficial";
  twitter_anchor.appendChild(twitter_img);
  let youtube_img = document.createElement("img");
  youtube_img.src = youtube_icon;
  youtube_anchor.href = "https://www.youtube.com/@LNBPOFICIAL";
  youtube_anchor.appendChild(youtube_img);

  social_networks.appendChild(facebook_anchor);
  social_networks.appendChild(instagram_anchor);
  social_networks.appendChild(twitter_anchor);
  social_networks.appendChild(youtube_anchor);

  //copy rights
  let copy_rights = document.createElement("div");
  copy_rights.classList.add(
    "col-sm-12",
    "col-lg-9",
    "col-md-9",
    styles.social_networks,
    styles.right
  );
  social_network_container.appendChild(copy_rights);

  let copy_rights_span = document.createElement("span");
  copy_rights_span.innerHTML =
    "Copyright LNBP Oficial, Todos los derechos resercados. Diseñado y Desarrollado por: ";

  let twt_anchor = document.createElement("a");
  twt_anchor.text = "True Wisdom Technologies";
  twt_anchor.href = coffee_main;

  let twt_img = document.createElement("img");
  twt_img.src = twt_icon;

  twt_anchor.appendChild(twt_img);
  copy_rights.appendChild(copy_rights_span);
  copy_rights.appendChild(twt_anchor);
}

function displayContent(base_url, id_season) {
  let container = document.getElementById("js__footer_contetn--content");

  let app_container = document.createElement("div");
  app_container.classList.add(
    "col-sm-12",
    "col-md-2",
    "col-lg-2",
    styles.app_container
  );
  app_container.classList.add();

  let teams_container = document.createElement("div");
  teams_container.classList.add(
    "col-sm-12",
    "col-md-4",
    "col-lg-4",
    styles.teams_container
  );

  let newsletter_container = document.createElement("div");
  newsletter_container.classList.add(
    "col-sm-12",
    "col-md-6",
    "col-lg-6",
    styles.newsletter_container
  );

  container.appendChild(app_container);
  container.appendChild(teams_container);
  container.appendChild(newsletter_container);

  //app
  let app_img = document.createElement("img");
  app_img.src = logo_app;
  app_img.classList.add(styles.app_container_lnbp_logo);

  let download_text = document.createElement("span");
  download_text.innerHTML = "DESCARGA LA APP";
  let dowload_text_here = document.createElement("span");
  dowload_text_here.innerHTML = "<b>¡AQUÍ!</b>";
  dowload_text_here.classList.add(styles.span_red);

  let shop_availability = document.createElement("span");
  shop_availability.innerHTML = "DISPONBIBLE EN";

  let android_link = document.createElement("a");
  android_link.href =
    "https://play.google.com/store/apps/details?id=co.truewisdom.lnbp";
  let google_store_logo = document.createElement("img");
  google_store_logo.src = logo_android;
  google_store_logo.classList.add(styles.app_container_store_logo);
  android_link.appendChild(google_store_logo);

  let ios_link = document.createElement("a");
  ios_link.href = "https://apps.apple.com/us/app/lnbp/id991206385";
  let apple_store_logo = document.createElement("img");
  apple_store_logo.classList.add(styles.app_container_store_logo);
  apple_store_logo.src = logo_apple;
  ios_link.appendChild(apple_store_logo);

  app_container.appendChild(app_img);
  app_container.appendChild(download_text);
  app_container.appendChild(dowload_text_here);
  app_container.appendChild(shop_availability);
  app_container.appendChild(android_link);
  app_container.appendChild(ios_link);

  let teams_title_container = document.createElement("div");
  teams_title_container.classList.add(styles.teams_title_container);
  let teams_title_check = document.createElement("img");
  teams_title_check.src = check_icon;
  teams_title_check.classList.add(styles.check_icon);

  let teams_title = document.createElement("span");
  teams_title.innerHTML = "Equipos Sitio Web";

  teams_title_container.appendChild(teams_title_check);
  teams_title_container.appendChild(teams_title);

  teams_container.appendChild(teams_title_container);

  //teams
  let teams = document.createElement("div");
  var ajax_data = {
    id_season: id_season,
  };
  $.ajax({
    type: "POST",
    url: base_url + "/sports/seasons/participants/latest",
    data: ajax_data,
  }).done(function (response) {
    response.items_team.forEach(function (item) {
      let team = document.createElement("a");
      team.style.color = "white";
      team.href = item.contact.website;
      if (item.name.length > 10) {
        team.innerHTML = item.name.slice(0, 10) + "...";
      } else {
        team.innerHTML = item.name;
      }
      let team_img = document.createElement("img");
      team_img.src = base_url + item.full_url_logo;

      team.appendChild(team_img);
      teams.appendChild(team);
    });
  });

  teams_container.appendChild(teams);

  let sponsor_1 = document.createElement("img");
  sponsor_1.classList.add(styles.sponsor_image);
  sponsor_1.src = logo_sponsor_1;

  let sponsor_2 = document.createElement("img");
  sponsor_2.src = logo_sponsor_2;
  sponsor_2.classList.add(styles.sponsor_image);

  let sponsor_3 = document.createElement("img");
  sponsor_3.src = logo_sponsor_3;
  sponsor_3.classList.add(styles.sponsor_image);

  let sponsor_4 = document.createElement("img");
  sponsor_4.src = logo_sponsor_4;
  sponsor_4.classList.add(styles.sponsor_image);

  teams_container.appendChild(sponsor_1);
  teams_container.appendChild(sponsor_2);
  teams_container.appendChild(sponsor_3);
  teams_container.appendChild(sponsor_4);

  //newsletter
  let newsletter_title_container = document.createElement("div");
  newsletter_title_container.classList.add(styles.teams_title_container);
  let newsletter_title_check = document.createElement("img");
  newsletter_title_check.src = check_icon;
  newsletter_title_check.classList.add(styles.check_icon);

  let newsletter_title = document.createElement("span");
  newsletter_title.innerHTML = "Suscribete a nuestro newsletter";

  newsletter_title_container.appendChild(newsletter_title_check);
  newsletter_title_container.appendChild(newsletter_title);

  newsletter_container.appendChild(newsletter_title_container);

  let name = document.createElement("input");
  name.type = "text";
  name.placeholder = "Nombre";
  name.id = "js__news_letter_name";
  name.classList.add(styles.newsletter_container_name);

  let last_name = document.createElement("input");
  last_name.type = "text";
  last_name.placeholder = "Apellido";
  last_name.id = "js__news_letter_last_name";

  last_name.classList.add(styles.newsletter_container_last_name);

  let email = document.createElement("input");
  email.type = "text";
  email.id = "js__news_letter_email";
  email.placeholder = "Correo electrónico";
  email.classList.add("w-100");

  let phone = document.createElement("input");
  phone.type = "text";
  phone.id = "js__news_phone";
  phone.placeholder = "Teléfono";
  phone.classList.add("w-100");

  let newsletter_submit_container = document.createElement("div");
  newsletter_submit_container.classList.add("row");

  let newsletter_checkbox_container = document.createElement("div");
  newsletter_checkbox_container.classList.add(
    "col-sm-1",
    "col-md-1",
    "col-lg-1",
    "text-center"
  );

  let newsletter_checkbox = document.createElement("input");
  newsletter_checkbox.type = "checkbox";
  newsletter_checkbox.id = "js__newsletter_checkbox";
  newsletter_checkbox_container.appendChild(newsletter_checkbox);

  let newsletter_conditions = document.createElement("div");
  newsletter_conditions.id = "js__news_letter_terms";
  newsletter_conditions.innerHTML =
    "*Acepto los términos, condiciónes y politicas de privacidad de la LNBP";
  newsletter_conditions.classList.add("col-sm-11", "col-md-7", "col-lg-7");

  let newsletter_submit = document.createElement("input");
  newsletter_submit.type = "Submit";
  newsletter_submit.id = "js__newsletter_submit";
  newsletter_submit.value = "Enviar";
  newsletter_submit.classList.add(
    "col-sm-12",
    "col-md-4",
    "col-lg-4",
    styles.newsletter_submit
  );

  newsletter_submit_container.appendChild(newsletter_checkbox_container);
  newsletter_submit_container.appendChild(newsletter_conditions);
  newsletter_submit_container.appendChild(newsletter_submit);

  newsletter_container.appendChild(name);
  newsletter_container.appendChild(last_name);
  newsletter_container.appendChild(email);
  newsletter_container.appendChild(phone);
  newsletter_container.appendChild(newsletter_submit_container);

  newsletter_submit.addEventListener("click", () => {
    let name = document.getElementById("js__news_letter_name");
    let last_name = document.getElementById("js__news_letter_last_name");
    let email = document.getElementById("js__news_letter_email");
    let phone = document.getElementById("js__news_phone");

    let newsletter_checkbox = document.getElementById(
      "js__newsletter_checkbox"
    );

    let terms = document.getElementById("js__news_letter_terms");

    if (newsletter_checkbox.checked == false) {
      terms.innerHTML =
        "*Acepto los términos, condiciónes y politicas de privacidad de la LNBP. <span style='color:red'>Requerido</span>";
    } else {
      terms.innerHTML =
        "*Acepto los términos, condiciónes y politicas de privacidad de la LNBP.";
    }

    if (name.value == "") {
      name.classList.add(styles.news_letter_require);
    } else {
      name.classList.remove(styles.news_letter_require);
    }

    if (last_name.value == "") {
      last_name.classList.add(styles.news_letter_require);
    } else {
      last_name.classList.remove(styles.news_letter_require);
    }
    if (email.value == "") {
      email.classList.add(styles.news_letter_require);
    } else {
      email.classList.remove(styles.news_letter_require);
    }
    if (phone.value == "") {
      phone.classList.add(styles.news_letter_require);
    } else {
      phone.classList.remove(styles.news_letter_require);
    }

    if (
      name.value != "" &&
      last_name.value != "" &&
      phone.value != "" &&
      email.value != "" &&
      newsletter_checkbox.checked != false
    ) {
      $.ajax({
        type: "POST",
        url: base_url + "/profile/users/newsletterbasic",
        data: {
          name: name.value,
          last_name: last_name.value,
          phone_number: phone.value,
          email: email.value,
          newsletter: 1,
        },
      }).done(function (response) {
        name.value = "";
        last_name.value = "";
        email.value = "";
        phone.value = "";
        newsletter_checkbox.checked = false;
        document.getElementById("js__newsletter_submit").value =
          "¡Gracias por suscribirte!";
      });
    } else {
      document.getElementById("js__newsletter_submit").value = "Enviar";
    }
  });
}

const methods = {
  render: (base_url, id_season) => {
    render(base_url, id_season);
  },
};

export default methods;
