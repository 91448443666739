import styles from './page-template.scss';

//load styles
//add classes with unique names
[...document.getElementsByClassName('main-content')].forEach(item => {
  item.classList.add(styles.main_content);
});

function addLoader() {
  document.getElementById('js__page_loader').classList.add(styles.loading);
}

function addWidget() {
  $(() => {
    [...document.getElementsByClassName('sw-ff-layout')].forEach(item => {
      item.classList.add(styles.sw_ff_layout);
    });
  });

}

function setPageTemplate() {
  addLoader();
  addWidget();
}

function hideLoader(hide = true) {
  if (hide) {
    $('#js__page_loader').hide();
  } else {
    $('#js__page_loader').show();
  }
}

const methods = {
  setPageTemplate,
  hideLoader: hide => {
    hideLoader(hide);
  },
};

export default methods;
